import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";
import { cloneDeep, head } from "lodash";
import router from "@/router";

export const GET_FILTER = "setLtxFilter";
export const GET_CUSTOMER = "getLtxFilterCustomer";
export const SET_CUSTOMER = "setLtxFilterCustomer";
export const GET_PERSON = "getLtxFilterPerson";
export const SET_PERSON = "setLtxFilterPerson";
export const GET_PROPERTY = "getLtxFilterProperty";
export const SET_PROPERTY = "setLtxFilterProperty";
export const GET_ENGINEER = "getLtxFilterEngineer";
export const SET_ENGINEER = "setLtxFilterEngineer";
export const GET_VISIT_STATUS = "getLtxFilterVisitStatus";
export const SET_VISIT_STATUS = "setLtxFilterVisitStatus";
export const SET_STATE = "setLtxFilterState";
export const SET_PRE_FILTER = "setLtxPreFilter";
export const CLEAR_FILTER = "clearLtxFilter";

const defaultFilter = {
  status: null,
  search: null,
  page: null,
  tab: null,
  date_range: null,
  customer: null,
  property: null,
  engineer: null,
  multiple_engineer: [],
  person: null,
  visit_status: null,
};

const defaultDropdown = {
  customer: false,
  property: false,
  engineer: false,
  person: false,
  visit_status: false,
};

const defaultLoading = {
  customer: false,
  property: false,
  engineer: false,
  person: false,
  visit_status: false,
};

export default {
  state: {
    db_filter_timeout: null,
    db_customer_timeout: null,
    db_property_timeout: null,
    db_person_timeout: null,
    db_engineer_timeout: null,
    db_filter_customers: [],
    db_filter_properties: [],
    db_filter_person: [],
    db_filter_engineers: [],
    db_filter_visit_status: [],
    db_pre_filter: cloneDeep(defaultFilter),
    db_filter: cloneDeep(defaultFilter),
    db_filter_dropdown: cloneDeep(defaultDropdown),
    db_filter_loading: cloneDeep(defaultLoading),
  },
  getters: {
    ltxFilterLoading(state) {
      return state.db_filter_loading;
    },
    ltxFilterDropDown(state) {
      return state.db_filter_dropdown;
    },
    ltxFilter(state) {
      return state.db_filter;
    },
    ltxFilterVisitStatusList(state) {
      return state.db_filter_visit_status;
    },
    ltxFilterCustomerList(state) {
      return state.db_filter_customers;
    },
    ltxFilterPropertyList(state) {
      return state.db_filter_properties;
    },
    ltxFilterPersonList(state) {
      return state.db_filter_person;
    },
    ltxFilterEngineerList(state) {
      return state.db_filter_engineers;
    },
  },
  actions: {
    [SET_PRE_FILTER](context, param) {
      const payload = router?.currentRoute?.query ?? param;

      if (payload.search) {
        context.commit(SET_STATE, {
          key: "db_filter.search",
          value: payload.search,
        });
      }

      if (payload.customer) {
        context.commit(SET_STATE, {
          key: "db_pre_filter.customer",
          value: payload.customer,
        });
      }

      if (payload.property) {
        context.commit(SET_STATE, {
          key: "db_pre_filter.property",
          value: payload.property,
        });
      }

      if (payload.engineer) {
        context.commit(SET_STATE, {
          key: "db_pre_filter.engineer",
          value: payload.engineer,
        });
      }

      if (payload.visit_status) {
        context.commit(SET_STATE, {
          key: "db_pre_filter.visit_status",
          value: payload.visit_status,
        });
      }

      if (payload.page) {
        context.commit(SET_STATE, {
          key: "db_pre_filter.page",
          value: payload.page,
        });
      }

      if (payload.tab) {
        context.commit(SET_STATE, {
          key: "db_pre_filter.tab",
          value: payload.tab,
        });
      }

      if (payload["date-range"]) {
        context.commit(SET_STATE, {
          key: "db_filter.date_range",
          value: payload["date-range"],
        });
      }
    },
    [GET_FILTER](context) {
      clearTimeout(context.state.db_filter_timeout);

      context.commit(SET_CUSTOMER, []);
      context.commit(SET_PERSON, []);
      context.commit(SET_PROPERTY, []);
      context.commit(SET_ENGINEER, []);
      context.commit(SET_VISIT_STATUS, []);

      const timeout = setTimeout(() => {
        ApiService.setHeader();
        ApiService.query("filters", {
          ...context.state.db_pre_filter,
          ...router?.currentRoute?.query,
        })
          .then(({ data }) => {
            context.commit(SET_CUSTOMER, data?.data?.customer ?? []);
            context.commit(SET_PERSON, data?.data?.contact_person ?? []);
            context.commit(SET_PROPERTY, data?.data?.property ?? []);
            context.commit(SET_ENGINEER, data?.data?.engineer ?? []);
            context.commit(SET_VISIT_STATUS, data?.data?.visit_status ?? []);
          })
          .catch((error) => {
            console.log(error, "error");
          });
      }, 500);

      context.commit(SET_STATE, { key: "db_filter_timeout", value: timeout });
    },
    [GET_CUSTOMER](context, payload) {
      clearTimeout(context.state.db_customer_timeout);

      const timeout = setTimeout(() => {
        ApiService.setHeader();
        context.commit(SET_STATE, {
          key: "db_filter_loading.customer",
          value: true,
        });
        ApiService.query("filter-customer", {
          ...context.state.db_pre_filter,
          ...payload,
        })
          .then(({ data }) => {
            console.log({ data });
            context.commit(SET_CUSTOMER, data?.data ?? []);
          })
          .catch((error) => {
            console.log(error, "error");
          })
          .finally(() => {
            context.commit(SET_STATE, {
              key: "db_filter_loading.customer",
              value: false,
            });
          });
      }, 500);

      context.commit(SET_STATE, { key: "db_customer_timeout", value: timeout });
    },
    [GET_VISIT_STATUS](context, payload) {
      ApiService.setHeader();
      context.commit(SET_STATE, {
        key: "db_filter_loading.visit_status",
        value: true,
      });
      ApiService.query("filter-visit-status", {
        ...context.state.db_pre_filter,
        ...payload,
      })
        .then(({ data }) => {
          context.commit(SET_VISIT_STATUS, data?.data ?? []);
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          context.commit(SET_STATE, {
            key: "db_filter_loading.visit_status",
            value: false,
          });
        });
    },
    [GET_PROPERTY](context, payload) {
      clearTimeout(context.state.db_property_timeout);

      const timeout = setTimeout(() => {
        ApiService.setHeader();
        context.commit(SET_STATE, {
          key: "db_filter_loading.property",
          value: true,
        });

        if (context?.state?.db_filter?.customer?.id) {
          payload = {
            ...context.state.db_pre_filter,
            ...payload,
            customer: context?.state?.db_filter?.customer?.id,
          };
        }

        ApiService.query("filter-property", payload)
          .then(({ data }) => {
            context.commit(SET_PROPERTY, data?.data ?? []);
          })
          .catch((error) => {
            console.log(error, "error");
          })
          .finally(() => {
            context.commit(SET_STATE, {
              key: "db_filter_loading.property",
              value: false,
            });
          });
      }, 500);

      context.commit(SET_STATE, { key: "db_property_timeout", value: timeout });
    },
    [GET_PERSON](context, payload) {
      clearTimeout(context.state.db_person_timeout);

      const timeout = setTimeout(() => {
        ApiService.setHeader();
        context.commit(SET_STATE, {
          key: "db_filter_loading.person",
          value: true,
        });

        if (context?.state?.db_filter?.customer?.id) {
          payload = {
            ...payload,
            customer: context?.state?.db_filter?.customer?.id,
          };
        }

        ApiService.query("filter-person", payload)
          .then(({ data }) => {
            context.commit(SET_PERSON, data?.data ?? []);
          })
          .catch((error) => {
            console.log(error, "error");
          })
          .finally(() => {
            context.commit(SET_STATE, {
              key: "db_filter_loading.person",
              value: false,
            });
          });
      }, 500);

      context.commit(SET_STATE, { key: "db_person_timeout", value: timeout });
    },
    [GET_ENGINEER](context, payload) {
      clearTimeout(context.state.db_engineer_timeout);

      const timeout = setTimeout(() => {
        ApiService.setHeader();
        context.commit(SET_STATE, {
          key: "db_filter_loading.engineer",
          value: true,
        });
        ApiService.query("filter-engineer", payload)
          .then(({ data }) => {
            context.commit(SET_ENGINEER, data?.data ?? []);
          })
          .catch((error) => {
            console.log(error, "error");
          })
          .finally(() => {
            context.commit(SET_STATE, {
              key: "db_filter_loading.engineer",
              value: false,
            });
          });
      }, 500);

      context.commit(SET_STATE, { key: "db_engineer_timeout", value: timeout });
    },
    [CLEAR_FILTER](context) {
      context.commit(CLEAR_FILTER);
      context.dispatch(GET_PERSON);
      context.dispatch(GET_PROPERTY);
    },
  },
  mutations: {
    [SET_STATE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
    [CLEAR_FILTER](state) {
      state.db_filter = cloneDeep(defaultFilter);
      state.db_pre_filter = cloneDeep(defaultFilter);
      state.db_filter_dropdown = cloneDeep(defaultDropdown);
      state.db_filter_loading = cloneDeep(defaultLoading);
      state.db_filter.visit_status = head(state.db_filter_visit_status);
    },
    [SET_CUSTOMER](state, payload) {
      state.db_filter_customers = cloneDeep(payload);

      if (state.db_pre_filter?.customer) {
        const findCustomer = payload.find(
          (row) => row.id == state.db_pre_filter?.customer
        );
        if (findCustomer?.id) {
          state.db_filter.customer = findCustomer;
        }
      }
    },
    [SET_PROPERTY](state, payload) {
      state.db_filter_properties = cloneDeep(payload);

      if (state.db_pre_filter?.property) {
        const findProperty = payload.find(
          (row) => row.id == state.db_pre_filter?.property
        );
        if (findProperty?.id) {
          state.db_filter.property = findProperty;
        }
      }
    },
    [SET_PERSON](state, payload) {
      state.db_filter_person = cloneDeep(payload);
    },
    [SET_VISIT_STATUS](state, payload) {
      state.db_filter_visit_status = cloneDeep(payload);
      state.db_filter.visit_status = head(payload);
    },
    [SET_ENGINEER](state, payload) {
      state.db_filter_engineers = cloneDeep(payload);

      if (state.db_pre_filter?.engineer) {
        const findEngineer = payload.find(
          (row) => row.id == state.db_pre_filter?.engineer
        );
        if (findEngineer?.id) {
          state.db_filter.engineer = findEngineer;
        }
      }
    },
  },
};
