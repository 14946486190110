import ApiService from "@/core/services/api.service";
import { map, filter } from "lodash";
// action types
export const SET_LOADING = "setLineItemLoading";

export const GET_FIELD_DATA = "getFieldData";
export const GET_ALL_MODULE = "getAllModule";
export const UPDATE_TITLE = "updateTitle";

export const SET_TITLE = "setFormTitle";
export const SET_TOTAL_COUNT = "setTotalCount";
export const SET_FIELD_DATA = "setFieldData";
export const SET_FORM_DATA = "setFormFieldData";
export const SET_CUSTOM_FORM_DETAIL = "setCustomFormDetail";
export const SET_ACTIVE_FIELD_DATA = "setActiveFieldData";
export const SET_ALL_MODULE = "setAllModule";
export const SET_ALL_MODULE_TITLE = "setAllModuleTitle";
export const SET_FILTER_FORM = "setFilterForm";
export const CLEAR_STATE = "clearFieldsState";

export default {
  state: {
    db_loading: false,
    db_total_count: {},
    db_form_title: null,
    db_form_fields: [],
    db_module_list: [],
    db_module_title: {},
    db_form_details: [],
    db_filter_form_data: [],
    db_active_form_fields: [],
    db_create_form_data: [],
    db_field_types: [
      {
        text: "Text (Single Line)",
        value: "text",
      },
      {
        text: "Text (Multi Line)",
        value: "textarea",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone",
        value: "phone",
      },
      {
        text: "Number",
        value: "number",
      },
      {
        text: "Date",
        value: "date",
      },
      {
        text: "Date and Time",
        value: "date-time",
      },
      {
        text: "Checkbox",
        value: "checkbox",
      },
      {
        text: "Radio",
        value: "radio",
      },
      {
        text: "Dropdown",
        value: "dropdown",
      },
      {
        text: "Attachment",
        value: "attachment",
      },
    ],
  },
  getters: {
    gLoading(state) {
      return state.db_loading;
    },
    gFieldTypes(state) {
      return state.db_field_types;
    },
    gAllModule(state) {
      return state.db_module_list;
    },
    gModuleTitle(state) {
      return state.db_module_title;
    },
    gTotalCount(state) {
      return state.db_total_count;
    },
    gFormTitle(state) {
      return state.db_form_title;
    },
    gFormDetail(state) {
      return state.db_form_details;
    },
    gFormFields(state) {
      return state.db_form_fields;
    },
    gActiveFormFields(state) {
      return state.db_active_form_fields;
    },
    gCreateFormFields(state) {
      return state.db_create_form_data;
    },
    gFilterFormFields(state) {
      return state.db_filter_form_data;
    },
  },
  actions: {
    [CLEAR_STATE](context) {
      context.commit(SET_LOADING, false);
    },
    [GET_FIELD_DATA](context, module) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING, true);
        ApiService.get("module/custom-fields/" + module)
          .then(({ data }) => {
            const payload = data.data;
            const _rows = map(payload.rows, (data) => {
              data.custom_id = data.id;
              data.unique_key = `${data.id}-${Number(new Date())}`;
              delete data.id;
              return data;
            });
            const _active_field = filter(
              _rows,
              (row) => row.status == "active"
            );
            const _filter_field = filter(
              _rows,
              (row) => row.status == "active" && row.is_filter
            );

            context.commit(SET_FIELD_DATA, _rows);
            context.commit(SET_TITLE, payload.module.title);
            context.commit(SET_TOTAL_COUNT, payload.count);
            context.commit(SET_FILTER_FORM, _filter_field);
            context.commit(SET_ACTIVE_FIELD_DATA, _active_field);
            resolve(data);
          })
          .catch((error) => {
            if (error && error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [UPDATE_TITLE](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post("module/updateTitle/" + payload.module, {
          title: payload.title,
        })
          .then(() => {
            context.commit(SET_TITLE, payload.title);
            resolve(payload.title);
          })
          .catch((error) => {
            if (error && error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    },
    [GET_ALL_MODULE](context) {
      return new Promise((resolve, reject) => {
        ApiService.get("module/all-modules")
          .then(({ data }) => {
            let _titleObj = {};
            const _list = map(data.data, (row) => {
              _titleObj[row.module] = row.title;
              return {
                ...row,
                text: row.module,
                value: row.module,
              };
            });
            context.commit(SET_ALL_MODULE, _list);
            context.commit(SET_ALL_MODULE_TITLE, _titleObj);
            resolve(data);
          })
          .catch((error) => {
            if (error && error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    },
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.db_loading = payload;
    },
    [SET_CUSTOM_FORM_DETAIL](state, payload) {
      state.db_form_details = payload;
    },
    [CLEAR_STATE](state) {
      state.db_loading = false;
      // state.db_total_count = {};
      // state.db_form_title = null;
      // state.db_form_fields = [];
    },
    [SET_FORM_DATA](state, payload) {
      state.db_create_form_data = payload;
    },
    [SET_ACTIVE_FIELD_DATA](state, payload) {
      state.db_active_form_fields = payload;
    },
    [SET_FIELD_DATA](state, data) {
      state.db_form_fields = data;
    },

    [SET_TOTAL_COUNT](state, payload) {
      state.db_total_count = payload;
    },
    [SET_TITLE](state, payload) {
      state.db_form_title = payload;
    },
    [SET_ALL_MODULE](state, payload) {
      state.db_module_list = payload;
    },
    [SET_ALL_MODULE_TITLE](state, payload) {
      state.db_module_title = payload;
    },
    [SET_FILTER_FORM](state, payload) {
      state.db_filter_form_data = payload;
    },
  },
};
