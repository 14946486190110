import moment from "moment-timezone";
import ObjectPath from "object-path";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageSchedule from "@/core/plugins/invoice.plugin";
import { cloneDeep /* toSafeInteger */ } from "lodash";

import {
  /*   SET_CUSTOMER, */
  RESET_STATE,
  /*  SET_BILLING,
    SET_BILLING_CONTACT,
    SET_PROPERTY,
    SET_PROPERTY_CONTACT, */
} from "@/core/services/store/visit.module";

import { toSafeNumber } from "@/core/plugins/math.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const todayDate = moment(new Date());

const formatDate = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format("DD MMMM YYYY hh:mm A");
  }
};

const validScheduleType = ["daily", "weekly", "monthly"];

const adjustedStartTime = moment(todayDate)
  .startOf("hour")
  .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");
const adjustedStartEnd = moment(todayDate)
  .add(1, "hour")
  .startOf("hour")
  .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");

const initialSchedule = {
  start_date: moment(todayDate).format("YYYY-MM-DD"),
  end_date: moment(todayDate).format("YYYY-MM-DD"),
  start_time: adjustedStartTime.format("hh:mm A"),
  end_time: adjustedStartEnd.format("hh:mm A"),
};

const initialRecurringSchedule = {
  start_date: moment(todayDate).format("YYYY-MM-DD"),
  end_date: moment(todayDate).add(1, "day").format("YYYY-MM-DD"),
  start_time: adjustedStartTime.format("hh:mm A"),
  end_time: adjustedStartEnd.format("hh:mm A"),
};

// action types
export const CLEAR_INVOICE = "JtxInvoiceClear";
export const UPDATE_CALENDAR_ACTION = "JtxInvoiceUpdateCalendarAction";
export const UPDATE_SCHEDULE_STATE = "JtxInvoiceUpdateScheduleState";
export const UPDATE_SCHEDULE_LIST = "JtxInvoiceUpdateScheduleState";
export const UPDATE_SCHEDULE_LIST_QUOTE = "JtxInvoiceUpdateScheduleStateQuote";
export const UPDATE_INVOICE_SCHEDULE_ONE_OFF = "JtxJobUpdateScheduleState";
export const UPDATE_INVOICE_SCHEDULE_RECURRING = "JtxJobUpdateRecurring";
export const UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE =
  "JtxJobUpdateRecurringQuote";
export const SET_SCHEDULE_TYPE = "JtxInvoiceSetScheduleType";
export const GET_RECURRING_DATE = "JtxInvoiceGetRecurringDate";
export const SET_RECURRING_DATE = "JtxInvoiceSetRecurringDate";
export const SET_INVOICE_RECURRING_DATE = "JtxVisitInvoiceSetRecurringDate";
export const SET_INVOICE_RECURRING_DATE_FOR_QUOTE =
  "JtxVisitInvoiceSetRecurringDateQuote";
export const SET_RAW_RECURRING_DATE = "JtxInvoiceSetRawRecurringDate";
export const SET_RAW_INVOICE_RECURRING_DATE =
  "JtxVisitInvoiceSetRawRecurringDate";
export const SET_LOADING = "JtxInvoiceSetInvoiceLoading";
export const UPDATE_DEFAULT_DATE = "JtxInvoiceUpdateDefaultDate";
export const UPDATE_ERROR_MESSAGE = "InvoiceUpdateError";
export const UPDATE_ERROR_MESSAGE_OCC = "InvoiceUpdateErrorOcc";

const dbDurationList = [
  { id: 1, short_name: "1st", long_name: "First" },
  { id: 2, short_name: "2nd", long_name: "Second" },
  { id: 3, short_name: "3rd", long_name: "Third" },
  { id: 4, short_name: "4th", long_name: "Fourth" },
  { id: 5, short_name: "Last", long_name: "Last" },
];

const dbWeekDays = [
  { id: 1, short_name: "Sun", long_name: "Sunday" },
  { id: 2, short_name: "Mon", long_name: "Monday" },
  { id: 3, short_name: "Tue", long_name: "Tuesday" },
  { id: 4, short_name: "Wed", long_name: "Wednesday" },
  { id: 5, short_name: "Thu", long_name: "Thursday" },
  { id: 6, short_name: "Fri", long_name: "Friday" },
  { id: 7, short_name: "Sat", long_name: "Saturday" },
];

const dbRecurringScheduleTypeList = [
  { max_allowed: 0, group: "daily", text: "Day(s)", value: "daily" },
  { max_allowed: 1, group: "weekly", text: "Week(s)", value: "weekly" },
  /* {
      max_allowed: 2,
      group: "weekly",
      text: "Twice a Week",
      value: "twice_a_week",
    },
    {
      max_allowed: 1,
      group: "weekly",
      text: "Fort-Nightly",
      value: "fort_nightly",
    },
    {
      max_allowed: 3,
      group: "weekly",
      text: "Thrice a Week",
      value: "thrice_a_week",
    }, */
  { max_allowed: 0, group: "monthly", text: "Month(s)", value: "monthly" },
  /*  {
      max_allowed: 0,
      group: "monthly",
      text: "Twice a Month",
      value: "twice_a_month",
    },
    {
      max_allowed: 0,
      group: "monthly",
      text: "BI-Monthly",
      value: "bi_monthly",
    },
    {
      max_allowed: 0,
      group: "monthly",
      text: "Quarterly",
      value: "quarterly",
    },
    {
      max_allowed: 0,
      group: "monthly",
      text: "BI-Annually",
      value: "bi_annually",
    }, */
  /* { max_allowed: 0, group: "monthly", text: "Year(s)", value: "annually" }, */
];

const dbJobScheduleTypeList = [
  {
    id: "one-off",
    value: "One-Off Job",
  },
  {
    id: "recurring",
    value: "Recurring Job",
  },
];

/* let selected = {}; */

const dbOneOffSchedule = {
  start_date: initialSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
  end_date: initialSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
  start_time: initialSchedule.start_time /* FORMAT - hh:mm A*/,
  end_time: initialSchedule.end_time /* FORMAT - hh:mm A*/,
};

const oneoffinvoice = {
  invoice_needed: 0,
  payment_due: 2,
  send_invoice: 1,
  invoice_type: "remind_me_job_closed",
};
const recurringinvoice = {
  invoice_needed: 0,
  invoice_main_type: "per_visit",
  payment_due: 2,
  send_invoice: 1,
  invoice_type: "no_need_auto",
};
const quoteInvoiceParameters = {
  invoice_needed: 0,
  invoice_type: "one-off",
  invoice_date: moment().format("YYYY-MM-DD"),
  payment_due: 2,
  invoice_generate_type: "auto_create_on_acceptance",
  invoice_amount_type: "total_amount",
};

const dbRecurringSchedule = {
  type: {
    group: "daily",
    text: "Day(s)",
    value: "daily",
  },
  weeks: [],
  occurrence: 1,
  week_day: 1,
  week_count: 1,
  second_week_day: 5,
  second_week_count: 1,
  recurring_pattern: 1,
  message: null,
  recurring_duration_new: 1,
  end_mode: 2 /* occurrences = 2; end-date = 3 */,
  start_date: initialRecurringSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
  end_date: initialRecurringSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
  start_time: initialRecurringSchedule.start_time /* FORMAT - hh:mm A*/,
  end_time: initialRecurringSchedule.end_time /* FORMAT - hh:mm A*/,
};

// mutation types
export default {
  state: {
    invoiceError: null,
    invoiceErrorOcc: null,
    dbDefaultStartedAt: null,
    dbDefaultFinishedAt: null,
    dbShowCalendar: false,
    isEdit: false,
    dbScheduleType: "recurring",
    dbAvailableTeam: [],
    dbAssignedTeam: [],
    dbAssignedTeamObject: [],
    dbDurationList: cloneDeep(dbDurationList),
    dbWeekDays: cloneDeep(dbWeekDays),
    dbRecurringScheduleTypeList: cloneDeep(dbRecurringScheduleTypeList),
    dbJobScheduleTypeList: cloneDeep(dbJobScheduleTypeList),
    dbOneOffSchedule: cloneDeep(dbOneOffSchedule),
    oneoffinvoice: cloneDeep(oneoffinvoice),
    recurringinvoice: cloneDeep(recurringinvoice),
    quoteInvoiceParameters: cloneDeep(quoteInvoiceParameters),
    dbRecurringSchedule: cloneDeep(dbRecurringSchedule),
    dbScheduleOutput: [],
    dbRawScheduleOutput: [],
    dbInvoiceVisitScheduleOutput: [],
    dbInvoiceRawScheduleList: [],
    dbJobOption: {},
    dbJobLoading: false,
    dbJobEditData: {},
    dbInvoiceQuoteUpdateSchedule: [],
  },
  getters: {
    invoiceDefaultStartedAt(state) {
      return state.dbDefaultStartedAt;
    },
    dbInvoiceUpdateSchedule(state) {
      return state.dbInvoiceQuoteUpdateSchedule;
    },
    customError(state) {
      return state.invoiceError;
    },
    customErrorOcc(state) {
      return state.invoiceErrorOcc;
    },

    invoiceDefaultFinishedAt(state) {
      return state.dbDefaultFinishedAt;
    },
    invoiceEditData(state) {
      return state.dbJobEditData;
    },
    invoiceOption(state) {
      return state.dbJobOption;
    },
    isEdit(state) {
      return state.isEdit;
    },
    invoiceScheduleOutput(state) {
      return state.dbScheduleOutput;
    },
    invoiceRawScheduleOutput(state) {
      return state.dbRawScheduleOutput;
    },
    invoiceVisitScheduleOutput(state) {
      return state.dbInvoiceVisitScheduleOutput;
    },
    invoiceRawScheduleList(state) {
      return state.dbInvoiceRawScheduleList;
    },
    invoiceScheduleType(state) {
      return state.dbScheduleType;
    },
    invoiceLoading(state) {
      return state.dbJobLoading;
    },
    invoiceOneOffSchedule(state) {
      return state.dbOneOffSchedule;
    },
    oneoffinvoice(state) {
      return state.oneoffinvoice;
    },
    recurringinvoice(state) {
      return state.recurringinvoice;
    },
    quoteInvoiceParameters(state) {
      return state.quoteInvoiceParameters;
    },
    invoiceRecurringSchedule(state) {
      return state.dbRecurringSchedule;
    },
    invoiceShowCalendar(state) {
      return state.dbShowCalendar;
    },

    invoiceWeekDays(state) {
      return state.dbWeekDays;
    },
    invoiceDurationList(state) {
      return state.dbDurationList;
    },
    recurringInvoiceScheduleTypeList(state) {
      return state.dbRecurringScheduleTypeList;
    },
    invoiceScheduleTypeList(state) {
      return state.dbJobScheduleTypeList;
    },
  },
  actions: {
    [GET_RECURRING_DATE](context) {
      const recurringSchedule = context.state.dbRecurringSchedule;

      if (!validScheduleType.includes(recurringSchedule?.type?.group)) {
        return false;
      }

      const resetRecurringSchedule = () => {
        context.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbRecurringSchedule.message",
          value: null,
        });
        context.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbRecurringSchedule.end_date",
          value: recurringSchedule.end_date,
        });
        context.commit(SET_RECURRING_DATE, []);
        context.commit(SET_RAW_RECURRING_DATE, []);
      };

      const startDate = moment(
        `${recurringSchedule.start_date} ${recurringSchedule.start_time}`
      ).format("YYYY-MM-DD HH:mm:ss");
      const occurrences =
        recurringSchedule.end_mode == 2
          ? toSafeNumber(recurringSchedule.occurrence)
          : 1000;
      const interval =
        recurringSchedule.recurring_duration_new > 1
          ? toSafeNumber(recurringSchedule.recurring_duration_new)
          : 1;
      const endDate =
        recurringSchedule.end_mode == 3
          ? moment(
              `${recurringSchedule.end_date} ${recurringSchedule.end_time}`
            ).format("YYYY-MM-DD HH:mm:ss")
          : null;
      const selectedDays = recurringSchedule.weeks;

      if (recurringSchedule?.type?.group == "weekly" && !selectedDays.length) {
        resetRecurringSchedule();
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select weekday.")
        );
        return false;
      }

      const weekDay = toSafeNumber(recurringSchedule.week_day, -1);
      const weekCount = toSafeNumber(recurringSchedule.week_count, -1);
      const secondWeekDay = toSafeNumber(recurringSchedule.second_week_day, -1);
      const secondWeekCount = toSafeNumber(
        recurringSchedule.second_week_count,
        -1
      );
      const pattern = toSafeNumber(recurringSchedule.recurring_pattern, 1);
      const endMode = toSafeNumber(recurringSchedule.end_mode, 2);

      const scheduleManager = new ManageSchedule(
        startDate,
        occurrences,
        endDate,
        endMode,
        pattern,
        weekDay,
        weekCount,
        secondWeekDay,
        secondWeekCount
      );
      let scheduleDate = [];

      if (recurringSchedule?.type?.value == "daily") {
        scheduleDate = scheduleManager.generateDailySchedule(interval);
      }

      if (recurringSchedule?.type?.group == "weekly") {
        if (recurringSchedule?.type?.value == "weekly") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            interval,
            selectedDays.slice(0, 7)
          );
        }

        if (recurringSchedule?.type?.value == "twice_a_week") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 2)
          );
        }

        if (recurringSchedule?.type?.value == "fort_nightly") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            2,
            selectedDays.slice(0, 1)
          );
        }

        if (recurringSchedule?.type?.value == "thrice_a_week") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 3)
          );
        }
      }

      if (recurringSchedule?.type?.group == "monthly") {
        if (recurringSchedule?.type?.value == "monthly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(interval);
        }

        if (recurringSchedule?.type?.value == "twice_a_month") {
          scheduleDate = scheduleManager.generateTwiceAMonthlySchedule();
        }

        if (recurringSchedule?.type?.value == "bi_monthly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(2);
        }

        if (recurringSchedule?.type?.value == "quarterly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(3);
        }

        if (recurringSchedule?.type?.value == "bi_annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(6);
        }

        if (recurringSchedule?.type?.value == "annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(interval);
        }
      }

      if (!scheduleDate.length) {
        resetRecurringSchedule();
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Oops... Dates are not available, please select valid schedule."
          )
        );
        return false;
      }

      const scheduleStartDate = scheduleDate.length ? scheduleDate[0] : null;
      const scheduleEndDate = scheduleDate.length
        ? new Date(scheduleDate[scheduleDate.length - 1])
        : null;

      const endTime = moment(
        `${recurringSchedule.start_date} ${recurringSchedule.end_time}`
      ).format("HH:mm:ss");

      let [hour, minute, second] = endTime.split(":");

      if (scheduleEndDate instanceof Date) {
        scheduleEndDate.setHours(hour);
        scheduleEndDate.setMinutes(minute);
        scheduleEndDate.setSeconds(second);
      }

      const message = String(scheduleManager.message)
        .replace("{DURATION}", recurringSchedule?.recurring_duration_new)
        .replace("{TYPE}", recurringSchedule?.type?.text)
        .replace("{START_DATE_TIME}", formatDate(scheduleStartDate))
        .replace("{OCCURRENCE}", scheduleDate.length)
        .replace("{END_DATE_TIME}", formatDate(scheduleEndDate));

      context.commit(UPDATE_SCHEDULE_STATE, {
        key: "dbRecurringSchedule.message",
        value: message,
      });
      /*  context.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbRecurringSchedule.end_date",
          value: moment(scheduleEndDate).format("YYYY-MM-DD"),
        }); */

      const combineDate = scheduleManager.combineDate(scheduleDate, endTime);

      context.commit(SET_RECURRING_DATE, combineDate);
      context.commit(SET_RAW_RECURRING_DATE, combineDate);
    },
    [CLEAR_INVOICE](context) {
      context.commit(CLEAR_INVOICE);
      context.commit(RESET_STATE);
      /*   context.commit(CLEAR_LINE_ITEM); */
    },
    [UPDATE_DEFAULT_DATE](context, payload) {
      let startedAt = null;
      let finishedAt = null;

      if (payload == "one-off") {
        startedAt = `${context.state.dbOneOffSchedule.start_date} ${context.state.dbOneOffSchedule.start_time}`;
        finishedAt = `${context.state.dbOneOffSchedule.end_date} ${context.state.dbOneOffSchedule.end_time}`;
      }

      if (context.state.dbJobEditData?.ticket?.id) {
        return false;
      }

      if (payload == "recurring") {
        startedAt = `${context.state.dbRecurringSchedule.start_date} ${context.state.dbRecurringSchedule.start_time}`;
        finishedAt = `${context.state.dbRecurringSchedule.end_date} ${context.state.dbRecurringSchedule.end_time}`;
      }

      context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });
    },
    [UPDATE_ERROR_MESSAGE](context, payload) {
      context.commit(UPDATE_ERROR_MESSAGE, payload);
    },
    [UPDATE_ERROR_MESSAGE_OCC](context, payload) {
      context.commit(UPDATE_ERROR_MESSAGE_OCC, payload);
    },
  },
  mutations: {
    [CLEAR_INVOICE](state) {
      state.dbDefaultStartedAt = null;
      state.invoiceError = null;
      state.invoiceErrorOcc = null;
      state.dbDefaultFinishedAt = null;
      state.dbShowCalendar = false;
      (state.isEdit = false),
        (state.dbInvoiceUpdateSchedule = []),
        (state.dbScheduleType = "one-off");
      state.dbAvailableTeam = [];
      state.dbAssignedTeam = [];
      state.dbAssignedTeamObject = [];
      state.dbDurationList = cloneDeep(dbDurationList);
      state.dbWeekDays = cloneDeep(dbWeekDays);
      state.dbRecurringScheduleTypeList = cloneDeep(
        dbRecurringScheduleTypeList
      );
      state.dbJobScheduleTypeList = cloneDeep(dbJobScheduleTypeList);
      state.dbOneOffSchedule = cloneDeep(dbOneOffSchedule);
      state.oneoffinvoice = cloneDeep(oneoffinvoice);
      state.recurringinvoice = cloneDeep(recurringinvoice);
      state.quoteInvoiceParameters = cloneDeep(quoteInvoiceParameters);
      state.dbRecurringSchedule = cloneDeep(dbRecurringSchedule);
      state.dbScheduleOutput = [];
      state.dbRawScheduleOutput = [];
      state.dbInvoiceVisitScheduleOutput = [];
      state.dbInvoiceRawScheduleList = [];
      state.dbJobOption = {};
      state.dbJobLoading = false;
      state.dbJobEditData = {};
    },

    [UPDATE_DEFAULT_DATE](state, { startedAt, finishedAt }) {
      state.dbDefaultStartedAt = startedAt
        ? moment(startedAt).format("YYYY-MM-DD HH:mm")
        : null;
      state.dbDefaultFinishedAt = finishedAt
        ? moment(finishedAt).format("YYYY-MM-DD HH:mm")
        : null;
    },
    [UPDATE_ERROR_MESSAGE](state, error) {
      state.invoiceError = error;
    },
    [UPDATE_ERROR_MESSAGE_OCC](state, error) {
      state.invoiceErrorOcc = error;
    },
    [SET_RECURRING_DATE](state, payload) {
      state.dbScheduleOutput = cloneDeep(payload);
    },
    [SET_RAW_RECURRING_DATE](state, payload) {
      state.dbRawScheduleOutput = cloneDeep(payload);
    },
    [SET_INVOICE_RECURRING_DATE](state, payload) {
      state.dbInvoiceVisitScheduleOutput = cloneDeep(payload);
    },
    [SET_RAW_INVOICE_RECURRING_DATE](state, payload) {
      state.dbInvoiceRawScheduleList = cloneDeep(payload);
    },
    [SET_INVOICE_RECURRING_DATE_FOR_QUOTE](state, payload) {
      state.dbScheduleOutput = cloneDeep(payload);
    },

    [SET_LOADING](state, payload) {
      state.dbJobLoading = payload;
    },
    [SET_SCHEDULE_TYPE](state, payload) {
      state.dbScheduleType = payload;
    },
    [UPDATE_CALENDAR_ACTION](state) {
      state.dbShowCalendar = !state.dbShowCalendar;
    },
    [UPDATE_SCHEDULE_STATE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
    [UPDATE_SCHEDULE_LIST](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
    [UPDATE_SCHEDULE_LIST_QUOTE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
    [UPDATE_INVOICE_SCHEDULE_ONE_OFF](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
    [UPDATE_INVOICE_SCHEDULE_RECURRING](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
    [UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
  },
};
