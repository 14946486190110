import { cloneDeep } from "lodash";
import ApiService from "@/core/services/api.service";
import ObjPath from "object-path";

// action types
export const SET_LEAVE_STATUS = "SetLeaveStatus";
export const GET_LEAVE_STATUS = "GetLeaveStatus";
/* export const GET_OPTION = "getVisitOption";
export const SET_OPTION = "setVisitOption"; */
export const SET_FILTER = "setFilter";
export const SET_LOADING = "setVisitLoading";
export const RESET_STATE = "resetVisitState";
export const RESET_CREATE_STATE = "resetVisitCreateState";

export default {
  state: {
    db_leave_status: [],
    db_leave_filter: {},
    db_option: {
      ticket_types: [],
    },
    db_loading: false,
  },
  getters: {
    getLeaveStatus(state) {
      return state.db_leave_status;
    },
    getLeaveFilter(state) {
      return state.db_leave_filter;
    },
    /*   vOption(state) {
      return state.db_option;
    }, */
  },
  actions: {
    [RESET_STATE](context) {
      context.commit(RESET_STATE);
    },
    [RESET_CREATE_STATE](context) {
      context.commit(RESET_CREATE_STATE);
    },
    [GET_LEAVE_STATUS](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LEAVE_STATUS, []);
        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.query("leave/status", payload)
          .then((response) => {
            context.commit(
              SET_LEAVE_STATUS,
              ObjPath.get(response, "data.data", [])
            );
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },

    [SET_LOADING](context, payload) {
      context.commit(SET_LOADING, payload);
    },
    [SET_FILTER](context, payload) {
      context.commit(SET_FILTER, payload);
    },
    /* [GET_OPTION](context, request) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.query("job/options", {
          ...request,
          customer: toSafeInteger(context?.state?.db_customer?.id),
          billing: toSafeInteger(context?.state?.db_billing?.id),
          property: toSafeInteger(context?.state?.db_property?.id),
          property_person: toSafeInteger(
            context?.state?.db_property_contact?.id
          ),
          billing_person: toSafeInteger(context?.state?.db_billing_contact?.id),
        })
          .then((response) => {
            context.commit(SET_OPTION, ObjPath.get(response, "data.data", {}));
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    }, */
  },
  mutations: {
    [RESET_CREATE_STATE](state) {
      state.db_leave_status = [];
      state.db_leave_filter = {};
      state.db_loading = false;
    },
    [RESET_STATE](state) {
      state.db_leave_status = [];
      state.db_leave_filter = {};
      state.db_loading = false;
    },

    [SET_LOADING](state, payload) {
      state.db_loading = payload;
    },
    [SET_FILTER](state, payload) {
      state.db_leave_filter = cloneDeep(payload);
    },
    [SET_LEAVE_STATUS](state, payload) {
      state.db_leave_status = cloneDeep(payload);
    },
  },
};
